import { CONSTANTS, IRequest, SERVER } from '../api';

export const UserAction = {
    register: register,
    deletes: deletes,
    deleteProfile: deleteProfile,
    getListProfile: getListProfile,
    countProfile: countProfile,
    getUserRfidCard: getUserRfidCard,
    getUserPasswordPin: getUserPasswordPin,
    getUserFingerPrint: getUserFingerPrint,
    getUserFingerPrintCount: getUserFingerPrintCount,
    getUserFace: getUserFace,
    downloadUserToDoor: downloadUserToDoor,
    updateUserInfo: updateUserInfo,
    getUserAccessInfo: getUserAccessInfo,
    updateUserAccessInfo: updateUserAccessInfo,

    getListDoors: getListDoors,
    updateUserAccessTime: updateUserAccessTime,
    updateUserAccessDate: updateUserAccessDate,
}

function register(data) {
    return IRequest.Post(SERVER.API.ProfileUsers.Register, data)
}
function deletes(userId) {
    return IRequest.Post(SERVER.API.AppUser.Removed, { id: userId })
}
function deleteProfile(userId) {
    return IRequest.Delete(SERVER.API.ProfileUsers.Removed(userId))
}
async function getListProfile(dispatch) {
    try {
        const result = await IRequest.GetQuery(SERVER.API.ProfileUsers.List)
        dispatch({ type: CONSTANTS.USER.LIST_FULFILLED, payload: result });
    } catch (error) {
        return Promise.reject(error)
    }
}
async function countProfile(dispatch) {
    try {
        const result = await IRequest.GetQuery(SERVER.API.ProfileUsers.Count)
        dispatch({ type: CONSTANTS.USER.COUNT_FULFILLED, payload: result });
    } catch (error) {
        return Promise.reject(error)
    }
}

function updateUserInfo(id, data) {
    return IRequest.Post(SERVER.API.ProfileUsers.UpdateUserInfo(id), data)
}

function getUserRfidCard(profileUserId) {
    return IRequest.Get(SERVER.API.ProfileUsers.UserRfidCard(profileUserId))
}
function getUserPasswordPin(profileUserId) {
    return IRequest.Get(SERVER.API.ProfileUsers.UserPasswordPin(profileUserId))
}
function getUserFingerPrint(profileUserId) {
    return IRequest.Get(SERVER.API.ProfileUsers.UserFingerPrint(profileUserId))
}

export function getUserFingerPrintCount(profileUserId) {
    return IRequest.Get(SERVER.API.ProfileUsers.UserFingerPrintCount(profileUserId))
}
export function getUserFace(profileUserId) {
    return IRequest.Get(SERVER.API.ProfileUsers.UserFace(profileUserId))
}

export function downloadUserToDoor(doorid, listId) {
    // return IRequest.Post(SERVER.API.ProfileUsers.DownloadToDoor(doorid), { user: listId })
    return IRequest.Post(SERVER.API.Doors.DownloadToDoor(doorid), { user: listId })
}

function getUserAccessInfo(profileUserId) {
    return IRequest.GetWithFilter(SERVER.API.ProfileUsers.UserAccessInfo, { where: { profileUserId: profileUserId } })
}
function updateUserAccessInfo(profileUserId, data) {
    return IRequest.Patch(SERVER.API.ProfileUsers.UpdateUserAccessInfo(profileUserId), data)
}

function getListDoors(profileUserId) {
    return IRequest.Get(SERVER.API.ProfileUsers.ListDoors(profileUserId))
}
function updateUserAccessTime(profileUserId, doorId, accessTime) {
    return IRequest.Post(SERVER.API.ProfileUsers.UpdateUserAccessTime(profileUserId, doorId), { accessTime: accessTime })
}
function updateUserAccessDate(profileUserId, doorId, accessDate) {
    return IRequest.Post(SERVER.API.ProfileUsers.UpdateUserAccessDate(profileUserId, doorId), { accessDate: accessDate })
}
