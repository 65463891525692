import { CONSTANTS, IRequest, SERVER } from '../api';

export const LogAction = {
    logList: logList,
    countAll: countAll,
    todayList: todayList
}

async function logList(dispatch, filter = null) {
    try {
        if (filter === null) {
            filter = {
                limit: 20,
                order: 'id desc'
            }
        }
        filter.order = 'id desc'
        filter.include = ["door"]

        const result = await IRequest.GetWithFilter(SERVER.API.Logs.MAIN, filter)
        dispatch({ type: CONSTANTS.LOGS.LIST_FULFILLED, payload: result });
    } catch (error) {
        return Promise.reject(error)
    }
}

async function countAll(dispatch) {
    try {
        const overall = await IRequest.GetQuery(SERVER.API.Logs.Count)
        const countIn = await IRequest.GetQuery(SERVER.API.Logs.Count)
        const countOut = await IRequest.GetQuery(SERVER.API.Logs.Count)

        const _data = {
            overall: overall.count,
            totalIn: countIn.count,
            totalOut: countOut.count
        }

        dispatch({ type: CONSTANTS.LOGS.COUNT_FULFILLED, payload: _data });
    } catch (error) {
        return Promise.reject(error)
    }
}

async function todayList(dispatch) {
    try {
        const result = await IRequest.GetWithFilter(SERVER.API.Doors.TodayList)
        dispatch({ type: CONSTANTS.LOGS.TODAY_LIST_FULFILLED, payload: result });
    } catch (error) {
        return Promise.reject(error)
    }
}

