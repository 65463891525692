import { CONSTANTS, IRequest, SERVER } from '../api';
export const DoorAction = {
    register: register,
    updates: updates,
    openDoor: openDoor,
    deletes: deletes,
    getList: getList,
    getListUsers: getListUsers,
    summaryInfo: summaryInfo,
    reCountUser: reCountUser,
    getAccessParameter: getAccessParameter,
    getNormalOpenTime: getNormalOpenTime,
    onUpdateToDevice: onUpdateToDevice,
    modifyLocation: modifyLocation,
    modifySerialId: modifySerialId,

    addAssignDoor: addAssignDoor,
    addUserToDoor: addUserToDoor,
    removeUserToDoor: removeUserToDoor,
    syncUserToDoorDevice: syncUserToDoorDevice,
    syncSelectedUserToDoorDevice: syncSelectedUserToDoorDevice,
    syncToDeviceUserAccessParameter: syncToDeviceUserAccessParameter,
}

function register(data) {
    return IRequest.Post(SERVER.API.Doors.Register, data)
}
function updates(id, data) {
    return IRequest.Post(SERVER.API.Doors.Modify(id), data)
}
function modifyLocation(id, location) {
    return IRequest.Post(SERVER.API.Doors.ModifyLocation(id), { location: location })
}
function modifySerialId(id, terminalId, serialId) {
    return IRequest.Post(SERVER.API.Doors.ModifySerialId(id, terminalId), { serialId: serialId })
}
function openDoor(id) {
    return IRequest.Post(SERVER.API.Doors.OpenDoor(id))
}
function deletes(id) {
    return IRequest.Post(SERVER.API.Doors.RemoveDoor(id))
}
async function getList(dispatch) {
    try {
        const result = await IRequest.GetQuery(SERVER.API.Doors.List)
        dispatch({ type: CONSTANTS.DOORS.LIST_FULFILLED, payload: result });
    } catch (error) {
        return Promise.reject(error)
    }
}
function getListUsers(doorid) {
    return IRequest.Get(SERVER.API.Doors.ListUsers(doorid))
}
async function summaryInfo(dispatch) {
    try {
        const countDoorActive = await IRequest.GetCount(SERVER.API.Doors.Count, { doorActivation: true })
        const countDoorAlert = await IRequest.GetCount(SERVER.API.Doors.Count, { isAlert: true })
        const countDoorOpen = await IRequest.GetCount(SERVER.API.Doors.Count, { isOpen: true })

        const _data = {
            activeDoor: countDoorActive.count,
            activeAlert: countDoorAlert.count,
            openedDoor: countDoorOpen.count
        }

        dispatch({ type: CONSTANTS.DOORS.COUNT_FULFILLED, payload: _data });
    } catch (error) {
        return Promise.reject(error)
    }
}
async function reCountUser(dispatch, doorId, currentCount) {
    try {
        const result = await IRequest.Get(SERVER.API.Doors.ReCountUser(doorId, currentCount))
        dispatch({ type: CONSTANTS.DOORS.UPDATE_INFO, payload: { id: doorId, info: { totaluser: result.count } } })
    } catch (error) {
        return Promise.reject(error)
    }
}
function getAccessParameter(doorId) {
    return IRequest.Get(SERVER.API.Doors.AccessParameter(doorId))
}
function getNormalOpenTime(doorId) {
    return IRequest.Get(SERVER.API.Doors.NormalOpenTime(doorId))
}
function onUpdateToDevice(id, data) {
    return IRequest.Post(SERVER.API.Doors.ParameterInfo(id), data)
}

function addAssignDoor(profileUserId, listDoors) {
    return IRequest.Post(SERVER.API.Doors.AddAssignDoor(profileUserId), { door: listDoors })
}
function addUserToDoor(doorId, listUsers) {
    return IRequest.Post(SERVER.API.Doors.AddUserToDoor(doorId), { user: listUsers })
}
function removeUserToDoor(doorId, profileUserId) {
    return IRequest.Post(SERVER.API.Doors.RemoveUserToDoor(doorId, profileUserId))
}
function syncUserToDoorDevice(doorId, profileUserId) {
    return IRequest.Post(SERVER.API.Doors.SyncUserToDoorDevice(doorId, profileUserId))
}
function syncSelectedUserToDoorDevice(doorId, listUser) {
    return IRequest.Post(SERVER.API.Doors.SyncSelectedUserToDoorDevice(doorId), { user: listUser })
}
function syncToDeviceUserAccessParameter(doorId, userId) {
    return IRequest.Post(SERVER.API.Doors.SyncToDeviceUserAccessParameter(doorId, userId))
}
