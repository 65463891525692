import { CONSTANTS, IRequest, SERVER } from '../api'
import Dates from '../__ifunc/dates'

export const LogAttendanceAction = {
  logList: logList,
  downloadReport: downloadReport
}

async function logList(dispatch, filter = null) {
  try {
    if (filter === null) {
      let dates = Dates.format(new Date(), Dates.FORMAT.DATE1)
      filter = {
        where: { or: [{ timein: { gt: dates + ' 00:00:00' } }, { timeout: { gt: dates + ' 00:00:00' } }] }
      }
    }
    const result = await IRequest.GetWithFilter(SERVER.API.LogAttendance.MAIN, filter)
    dispatch({ type: CONSTANTS.LOG_ATTENDANCE.LIST_FULFILLED, payload: result });
  } catch (error) {
    return Promise.reject(error)
  }
}

function downloadReport(startDate, endDate) {
  startDate = Dates.format(startDate, Dates.FORMAT.DATE1)
  endDate = Dates.format(endDate, Dates.FORMAT.DATE1)
  return IRequest.GetQuery(SERVER.API.LogAttendance.DOWNLOAD_REPORT, { startDate: startDate, endDate: endDate })
}
