import { CONSTANTS } from '../api/constants';
let initState = {
    error: null,
    message: "",
    dayData: [],
    dayDataList: [],
    weekDataList: [],
    pagination: {
        day: {
            itemPerPage: 10,
            currentPage: 1,
            totalPage: 1
        },
        week: {
            itemPerPage: 10,
            currentPage: 1,
            totalPage: 1
        },
    },
    searchValue: '',
    dataSearch: [],
}
export function timezone(state = initState, action) {
    let __state = {}
    let _j = 0

    switch (action.type) {
        case CONSTANTS.TIMEZONE.LIST_DAY:
            state = {
                ...state,
                dayDataList: action.payload,
                dayData: action.payload,
                error: null,
                pagination: {
                    ...state.pagination,
                    day: {
                        itemPerPage: 10,
                        currentPage: 1,
                        totalPage: Math.ceil(action.payload.length / state.pagination.day.itemPerPage),
                    }
                },
            };
            break;
        case CONSTANTS.TIMEZONE.LIST_WEEKY:
            state = {
                ...state,
                weekDataList: action.payload,
                weekData: action.payload,
                error: null,
                pagination: {
                    ...state.pagination,
                    week: {
                        itemPerPage: 10,
                        currentPage: 1,
                        totalPage: Math.ceil(action.payload.length / state.pagination.week.itemPerPage),
                    }
                },
            };
            break;
        case CONSTANTS.TIMEZONE.DAY_COUNT_CHANGE:
            __state = { ...state }
            __state.pagination.day.itemPerPage = action.payload
            __state.pagination.day.currentPage = 1
            __state.pagination.day.totalPage = Math.ceil(__state.dayData.length / action.payload)

            __state.dayDataList = []

            for (let i = 0; i < __state.dayData.length; i++) {
                if (i < action.payload) {
                    __state.dayDataList.push(__state.dayData[i])
                }
            }

            state = {
                ...__state
            };
            break;
        case CONSTANTS.TIMEZONE.DAY_PAGE_CHANGE:
            __state = { ...state }
            __state.pagination.day.currentPage = action.payload
            __state.dayDataList = []

            _j = 0

            if (state.searchValue === '') {
                for (let i = ((action.payload - 1) * state.pagination.day.itemPerPage); i < __state.dayData.length; i++) {
                    if (_j < state.pagination.day.itemPerPage) {
                        __state.dayDataList.push(__state.dayData[i])
                        _j++
                    }
                }
            } else {
                for (let i = ((action.payload - 1) * state.pagination.day.itemPerPage); i < __state.dataSearch.length; i++) {
                    if (_j < state.pagination.day.itemPerPage) {
                        __state.dayDataList.push(__state.dataSearch[i])
                        _j++
                    }
                }
            }

            state = {
                ...__state
            };
            break;
        case CONSTANTS.TIMEZONE.WEEK_COUNT_CHANGE:
            __state = { ...state }
            __state.pagination.week.itemPerPage = action.payload
            __state.pagination.week.currentPage = 1
            __state.pagination.week.totalPage = Math.ceil(__state.weekData.length / action.payload)

            __state.weekDataList = []

            for (let i = 0; i < __state.weekData.length; i++) {
                if (i < action.payload) {
                    __state.weekDataList.push(__state.weekData[i])
                }
            }

            state = {
                ...__state
            };
            break;
        case CONSTANTS.TIMEZONE.WEEK_PAGE_CHANGE:
            __state = { ...state }
            __state.pagination.week.currentPage = action.payload
            __state.weekDataList = []

            _j = 0

            if (state.searchValue === '') {
                for (let i = ((action.payload - 1) * state.pagination.week.itemPerPage); i < __state.weekData.length; i++) {
                    if (_j < state.pagination.week.itemPerPage) {
                        __state.weekDataList.push(__state.weekData[i])
                        _j++
                    }
                }
            } else {
                for (let i = ((action.payload - 1) * state.pagination.week.itemPerPage); i < __state.dataSearch.length; i++) {
                    if (_j < state.pagination.week.itemPerPage) {
                        __state.weekDataList.push(__state.dataSearch[i])
                        _j++
                    }
                }
            }

            state = {
                ...__state
            };
            break;

        case CONSTANTS.CLEAR:
            return initState
        default:
            return state;
    }
    return state;
}