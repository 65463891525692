import { CONSTANTS } from '../api/constants'
import Dates from '../__ifunc/dates'
let initState = {
  error: null,
  message: "",
  data: [],
  dataList: [],
  pagination: {
    itemPerPage: 10,
    currentPage: 1,
    totalPage: 1
  },
  searchValue: '',
  dataSearch: [],
}
export function logattendance(state = initState, action) {
  let __state = {}
  switch (action.type) {
    case CONSTANTS.LOG_ATTENDANCE.LIST_FULFILLED:
      let _data = []
      // console.log("action payload", action.payload)
      for (let i = 0; i < action.payload.length; i++) {
        action.payload[i].no = i + 1
        action.payload[i].dates = (action.payload[i].timein) ? Dates.format(action.payload[i].timein, Dates.FORMAT.DATE3) : Dates.format(action.payload[i].timeout, Dates.FORMAT.DATE3)
        action.payload[i].timein = (action.payload[i].timein === null) ? '-' : Dates.format(action.payload[i].timein, Dates.FORMAT.TIME3)
        action.payload[i].timeout = (action.payload[i].timeout === null) ? '-' : Dates.format(action.payload[i].timeout, Dates.FORMAT.TIME3)

        if (i < 10) {
          _data.push(action.payload[i])
        }
      }

      state = {
        ...state,
        data: action.payload,
        dataList: _data,
        pagination: {
          itemPerPage: 10,
          currentPage: 1,
          totalPage: Math.ceil(action.payload.length / state.pagination.itemPerPage)
        },
        error: null
      };
      break;
    case CONSTANTS.LOG_ATTENDANCE.COUNT_CHANGE:
      __state = { ...state }
      __state.pagination.itemPerPage = action.payload
      __state.pagination.currentPage = 1
      __state.pagination.totalPage = Math.ceil(__state.data.length / action.payload)

      __state.dataList = []
      for (let i = 0; i < __state.data.length; i++) {
        if (i < action.payload) {
          __state.dataList.push(__state.data[i])
        }
      }

      state = {
        ...__state
      };
      break;
    case CONSTANTS.LOG_ATTENDANCE.PAGE_CHANGE:
      __state = { ...state }
      __state.pagination.currentPage = action.payload

      __state.dataList = []
      let _j = 0
      for (let i = ((action.payload - 1) * state.pagination.itemPerPage); i < __state.data.length; i++) {
        if (_j < state.pagination.itemPerPage) {
          __state.dataList.push(__state.data[i])
          _j++
        }
      }

      state = {
        ...__state
      };
      break;
    case CONSTANTS.LOG_ATTENDANCE.SEARCH:
      __state = { ...state }
      __state.dataList = []
      __state.dataSearch = []

      __state.pagination.currentPage = 1
      __state.pagination.searchValue = action.payload

      state.data.forEach(element => {
        if (element.names.includes(action.payload)) {
          __state.dataSearch.push(element)
        }
      });

      __state.pagination.totalPage = Math.ceil(__state.dataSearch.length / state.pagination.itemPerPage)

      _j = 0
      for (let i = 0; i < __state.dataSearch.length; i++) {
        if (_j < state.pagination.itemPerPage) {
          __state.dataList.push(__state.dataSearch[i])
          _j++
        }
      }

      state = {
        ...__state
      };

      break;
    case CONSTANTS.CLEAR:
      return initState
    default:
      return state;
  }
  return state;
}